import React from "react"
import { graphql } from "gatsby"
import theme from "../shared/theme"
import styled from "@emotion/styled"
import Button from "../elements/button"
import * as BlockContent from "@sanity/block-content-to-react"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

export const query = graphql`
  {
    allSanityImpressum {
      edges {
        node {
          impressum
          _rawTextImpressum
        }
      }
    }
  }
`

const Impressum = ({ data }) => (
  <Layout>
    <Body>
      <h1>
        {data.allSanityImpressum.edges[1].node.impressum
          ? data.allSanityImpressum.edges[1].node.impressum
          : ""}
      </h1>
      <div>
        <BlockContent
          blocks={
            data.allSanityImpressum.edges[1].node._rawTextImpressum
              ? data.allSanityImpressum.edges[1].node._rawTextImpressum
              : ""
          }
          serializers={{ types: { block: BlockRenderer } }}
        />
      </div>
      <Button ariaLabel="zur Startseite" linkTo="/">
        ZUR STARTSEITE
      </Button>
    </Body>
  </Layout>
)

const Body = styled.div`
  background-color: ${theme.colors.bodyBackground};
  padding: ${theme.spaces.standardPadding};
  color: ${theme.colors.darkFont};

  div {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-bottom: ${theme.spaces.standardPadding};
  }

  h1 {
    font-family: ${theme.fontFamilyHeading};
    text-transform: uppercase;
    padding-bottom: ${theme.spaces.standardPadding};
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }

  @media all and (max-width: 768px) {
    div {
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }
  }
`

export default Impressum
